<template>
  <ul class="mainLinks hide-on-lg">
    <li>
      <router-link to="/brand">
        <svg-icon icon-id="compaign" icon-viewbox="0 0 14.788 13.238">
        </svg-icon>
        Campaign
      </router-link>
    </li>
    <li>
      <router-link to="/brand/influencers">
        <svg-icon icon-id="Influencers" icon-viewbox="0 0 20.738 11.668">
        </svg-icon>
        Influencers
      </router-link>
    </li>
    <li v-if=showDeals>
      <router-link to="/brand/deals">
        <svg-icon icon-id="deal" icon-viewbox=" 0 0 14.788 13.238"> </svg-icon>
        Deal
      </router-link>
    </li>
  </ul>
</template>

<script>
export default {
  data(){
      return {
        showDeals: false,
    };
  }
};
</script>

<style></style>
